<template>
  <div class="row m-0 align-items-center borders py-md-3 py-4" >
    <!--IMAGE-->
    <div class="col-12 col-md-auto text-center"  v-if="projectDetails">
      <Image :imageName="projectDetails.imageUrl" width="40" theClass="item_img" v-if="projectDetails.imageUrl"/>
      <img src="../assets/images/default.png"  class="item_img" v-else>
    </div>
     <!--END IMAGE-->
    <div class="col-12 col-md p-0 text-center text-md-start mt-2  mt-md-0">
      <div class="row m-0">
        <div class="col-md-12 p-0 green-text bold">
          <span v-if="projectDetails">
            {{projectDetails.description}}
            <span v-if="projectDetails.location">
              <span v-if="projectDetails.location.name"> | {{projectDetails.location.name}}</span>
            </span>
          </span>
        </div>
        <div class="col-md-12 p-0 font12">
          <span v-for="(type, index) in dAmount" :key="index">
            <span class="text-nowrap">{{ item.currency }} {{ parseFloat(Math.round(type.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}}</span> <span class="text-nowrap">{{ type.type.donationType.description }}</span>
            <span v-if="dAmount.length > 1 && index + 1 < dAmount.length"> | </span>
          </span>
        </div>
        <div class="col-md-12 p-0 font12" v-if="item.plaqueDescription">
          <div class="row mt-2 justify-content-center justify-content-md-start">
            <div class="col-11 col-md-auto mb-1">
              {{item.plaqueDescription}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col text-center md-text-end bold mt-3 mt-md-0 text-nowrap green-text col-md-auto" >
      {{ item.currency }}{{displayTotal}}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Image: defineAsyncComponent(() => import('./Image.vue'))
  },
  name: 'CartItemMenu',
  props: ['item'],
  data () {
    return {
      url: process.env.VUE_APP_URL_BASE
    }
  },
  computed: {
    ...mapGetters([
      'adminProjects',
      'contentProjects'
    ]),
    projectDetails () {
      const adminProject = this.adminProjects.find(project => project.projectID === this.item.projectId)
      const contentProject = this.contentProjects.find(project => project.adminProjectId === this.item.projectId)
      if (contentProject) {
        const mergedProject = { ...contentProject, ...adminProject }
        return mergedProject
      } else {
        return adminProject
      }
    },
    dAmount () {
      return this.item.donationTypes.filter(type => {
        if (type.amount === 0 || type.amount === '0' || type.amount === null) {
          return false
        }
        return true
      })
    },
    displayTotal () {
      return parseFloat(Math.round(this.item.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }
  }
}
</script>

<style scoped>
.item_img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.1);
}

.borders {
  border-bottom: 1px solid #E0E0E0;
}
.borders:hover {
  background-color: rgb(244, 244, 244);
}
</style>
